import { BloomAddress } from "../api/generated";

type PartialBloomAddress = Partial<BloomAddress> | null | undefined;

const useAddress = ({ address }: { address: PartialBloomAddress }) => {
  if (!address) return "";

  const shippingAddress = { ...address };
  const addressFormat = `${shippingAddress?.street ?? ""} ${shippingAddress?.houseNumber ?? ""}, ${shippingAddress?.zip ?? ""
    } ${shippingAddress?.city ?? ""}, ${shippingAddress?.country ?? ""}`;

  return addressFormat;
};

export const getAvailableAddress = ({
  shippingAddress,
  billingAddress,
}: {
  shippingAddress?: PartialBloomAddress;
  billingAddress?: PartialBloomAddress;
}) => {
  return shippingAddress || billingAddress || null;
};

export default useAddress;
