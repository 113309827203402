import * as Sentry from '@sentry/react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import environment from '../config/environment'
import useAuth0 from './useAuth0'
import useBackendError from './useBackendError'

/**
 * Shows errors returned from a graphql operation
 *
 * @returns
 */

//TODO: Change to explanation
const useGraphqlError = () => {
  const { t } = useTranslation()
  const backendErrors = useBackendError()
  const { logOut } = useAuth0('admin')

  return {
    showError: async (e: any) => {
      const host = `${window?.location?.protocol}//${window?.location?.host}`
      const isAdmin =
        host.includes(environment.adminBaseUrl) ||
        environment.adminBaseUrl.includes(host)
      Sentry.captureException(e)
      const errorValue = e?.response?.errors?.map((i: any) => {
        const foundError = backendErrors.find((x) => {
          return i?.message?.includes(x?.type)
        })
        // Here we include the backend messages as it sometimes contains more information
        const foundError2 = `${foundError?.description ?? ''} \n ${
          i?.extensions?.explanation ?? i?.message ?? t('Something went wrong')
        }`

        // we should NOT log people out of the webshop, when they get unauthorized this causes them to redirect to the login page
        // after logout, as we call auth 0 logout?redirect_url=home page
        // which means we loose the redirectUrl we will set when they are asked to sign in
        // instead we should rely on the use effect in AfterUserInitialization to set the redirect url.
        // this means you need to make a page protected with the page params by the looks of things:
        // gb-frontends/libs/common-ui/src/lib/AfterUserInitialization.tsx
        if (i.extensions?.code === 'ERROR_UNAUTHORIZED' && isAdmin) {
          logOut('admin')
        }

        return foundError2
      })

      toast.error(errorValue?.join('\n'))
    }
  }
}

export default useGraphqlError
